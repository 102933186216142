import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { AnimatePresence, cubicBezier, motion } from 'framer-motion';
import type { FC, PropsWithChildren } from 'react';

import { useProfile } from '@/hooks/useProfile';

import Button from '../../Button';
import Text from '../../Text';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../../ui/accordion';
import type { MenuType } from './Header';

type Props = {
  menu: MenuType;
  onClose: () => void;
  isOpen: boolean;
};

const easing = cubicBezier(0.82, 0, 0.22, 0.99);
const Hamburger: FC<PropsWithChildren<Props>> = ({
  onClose,
  isOpen,
  menu,
  children,
}) => {
  const { data: profileData } = useProfile();
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          role="presentation"
          onClick={onClose}
          transition={{ ease: easing }}
          className={classNames('absolute left-0 top-0 h-full w-full z-[1]', {
            '-z-50': !isOpen,
          })}
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute -z-40 h-full w-full backdrop-blur-sm"
          />
          <motion.div
            initial={{
              backgroundImage:
                'radial-gradient(circle at top right, #27125A 0%, transparent 0%)',
            }}
            animate={{
              backgroundImage:
                'radial-gradient(circle at top right, #27125A 100%, transparent 0%)',
            }}
            exit={{
              backgroundImage:
                'radial-gradient(circle at top right, #27125A 0%, transparent 0%)',
            }}
            transition={{ ease: easing, duration: 0.5 }}
            role="presentation"
            onClick={(e) => e.stopPropagation()}
            className={classNames(
              'overflow-hidden fixed right-0 top-0 flex h-3/4 w-96 flex-col justify-between rounded-bl-3xl pb-10 pl-14 pr-5 pt-5'
            )}
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="flex h-full flex-col"
              transition={{ ease: easing }}
            >
              <Button
                className="self-end"
                variant="unstyled"
                size={null}
                onClick={onClose}
              >
                <FontAwesomeIcon icon={faXmark} size="2x" />
              </Button>
              <div className="flex h-full flex-col justify-between pt-10">
                <div className=" items-left flex flex-col  gap-4">
                  {menu.map(({ name, href, submenu, adminOnly }) => {
                    if (adminOnly && profileData?.accountType !== 'ADMIN')
                      return null;
                    return (
                      <div key={name}>
                        {!submenu && (
                          <a href={href} key={name}>
                            <Text>{name}</Text>
                          </a>
                        )}
                        {submenu && (
                          <Accordion type="single" collapsible>
                            <AccordionItem
                              value={`${name}`}
                              className="border-b-0"
                            >
                              <AccordionTrigger className="flex flex-row items-center gap-1 hover:no-underline">
                                <Text>{name}</Text>
                              </AccordionTrigger>
                              <AccordionContent className="pl-3">
                                <div className="flex flex-col gap-3">
                                  {submenu.map(
                                    ({ href: subHref, name: subName }) => (
                                      <a
                                        href={subHref}
                                        key={`${subName}${subHref}`}
                                        className="flex"
                                      >
                                        <Text
                                          className="w-full"
                                          variant="body-small"
                                          key={`${subName}${subHref}`}
                                        >
                                          {subName}
                                        </Text>
                                      </a>
                                    )
                                  )}
                                </div>
                              </AccordionContent>
                            </AccordionItem>
                          </Accordion>
                        )}
                      </div>
                    );
                  })}
                </div>
                <div className="flex w-full flex-col items-center justify-end gap-2">
                  {children}
                </div>
              </div>
            </motion.div>
            <motion.div
              initial={{ y: 200 }}
              animate={{ y: 0 }}
              exit={{ y: 200, opacity: 0 }}
              transition={{ ease: easing }}
              className="flex gap-2 self-end"
            />
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Hamburger;
