import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon as BaseIcon } from '@iconify/react';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import classNames from 'classnames';
import type { ComponentProps } from 'react';

export const iconStyles = cva('', {
  variants: {
    variant: {
      unstyled: '',
      candidateSuccess: 'rounded bg-jb-success-200 text-jb-success-400',
      candidateSuccessDark: 'rounded bg-jb-success-200 text-jb-success-600',
      candidateError: 'rounded bg-jb-alert-300 text-jb-alert-400',
      candidatePrimary: 'rounded bg-jb-primary-200 text-jb-primary-500',
      candidateSecondary: 'rounded bg-jb-secondary-100 text-jb-secondary-500',
      dark: 'rounded bg-[#333345] text-jb-neutral-100',
    },
  },
  defaultVariants: {
    variant: 'unstyled',
  },
});

type IconProps<T> = T extends string
  ? Omit<ComponentProps<typeof BaseIcon>, 'icon'>
  : Omit<ComponentProps<typeof FontAwesomeIcon>, 'icon'>;

type Props<T> = {
  icon: T;
  className?: string;
} & VariantProps<typeof iconStyles> &
  IconProps<T>;

const Icon = <T extends string | IconDefinition>({
  icon,
  className,
  variant,
  ...props
}: Props<T>) => {
  if (typeof icon === 'string') {
    return (
      <BaseIcon
        icon={icon}
        className={classNames(className, iconStyles({ variant }))}
        {...(props as IconProps<string>)}
      />
    );
  }

  return (
    <FontAwesomeIcon
      icon={icon}
      className={classNames(className, iconStyles({ variant }))}
      {...(props as IconProps<IconDefinition>)}
    />
  );
};

export default Icon;
